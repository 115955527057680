import clsx from "clsx";
import _ from "lodash";
import { useLoaderData } from "react-router";
import Alert from "~/components/alert";
import ButtonLink from "~/components/button-link";
import NumberedTable from "~/components/numbered-table";
import Panel from "~/components/panel";
import ProjectLink from "~/components/projects/link";
import type { CampaignsQuery } from "~/types/api";
import { dayIsBefore, formatDate } from "~/utils/dates";
import { formatNumber } from "~/utils/formatting";

export default function Campaigns() {
  const {
    campaignData: { projects }
  } = useLoaderData<{ campaignData: CampaignsQuery }>();

  return (
    <Panel>
      <Panel.Header
        title="Active Campaigns"
        titleClassName="text-center"
        floatButton
        button={
          <ButtonLink to="/campaigns" className="show-when-hover-parent" small>
            Campaign List
          </ButtonLink>
        }
      />
      {!projects.length ? (
        <Alert mode="warning">You don&apos;t have any active campaigns</Alert>
      ) : (
        <NumberedTable>
          <thead>
            <tr>
              <th />
              <th>Campaign</th>
              <th className="text-center">Contacts</th>
              <th className="text-center">Started</th>
              <th className="text-center">Target</th>
            </tr>
          </thead>
          <tbody>
            {_.sortBy(projects, "number").map((c) => (
              <tr key={c.id}>
                <td />
                <td>
                  <ProjectLink project={c} />
                </td>
                <td className="text-center">
                  {formatNumber(c.campaignsContactsCount, { format: "0,0" })}
                </td>
                <td className="text-center">{formatDate(c.createdAt)}</td>
                <td
                  className={clsx(
                    "text-center",
                    c.campaignTargetDate &&
                      dayIsBefore(c.campaignTargetDate, new Date()) &&
                      "text-danger font-bold"
                  )}
                >
                  {formatDate(c.campaignTargetDate)}
                </td>
              </tr>
            ))}
          </tbody>
        </NumberedTable>
      )}
    </Panel>
  );
}
