import { useLoaderData } from "react-router";
import invariant from "tiny-invariant";
import RecentCollectionsGauge from "~/components/ar/recent-collections-gauge";
import IndividualBoard from "~/components/kanban/individual-board";
import CollectionsProjects from "~/components/scoreboard/collections-projects";
import IndividualRocks from "~/components/scoreboard/individual-rocks";
import QuarterlyMetrics from "~/components/scoreboard/quarterly-metrics";
import TaskCounts from "~/components/scoreboard/task-counts";
import type { loader } from "~/routes/_index/route";

export default function DashboardAR() {
  const { quarter, dashboard } = useLoaderData<typeof loader>();
  invariant(dashboard === "A/R", "Dashboard data doesn't match");

  return (
    <div>
      <div className="mb-8 grid w-full grid-cols-3 gap-8">
        <div>
          <IndividualRocks />
          <TaskCounts />
        </div>
        <div>
          <RecentCollectionsGauge />
        </div>
        <div>
          <QuarterlyMetrics quarter={quarter} accountsReceivable />
        </div>
      </div>
      <div className="grid grid-cols-2 gap-8">
        <div>
          <CollectionsProjects
            tagId="175"
            top10
            title="Assertive Collections: Top 10"
          />
        </div>
        <div>
          <CollectionsProjects
            tagId="148"
            title="Management Intervention Required"
          />
          <CollectionsProjects tagId="161" title="Refunds Due: Approved" />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-4">
          <IndividualBoard />
        </div>
      </div>
    </div>
  );
}
