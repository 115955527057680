import _ from "lodash";
import { useContext } from "react";
import { useLoaderData } from "react-router";
import invariant from "tiny-invariant";
import Badge from "~/components/badge";
import ButtonLink from "~/components/button-link";
import { CONTENT_STATUSES_WITH_DESCRIPTIONS } from "~/components/content/form";
import { ListGroup } from "~/components/list-group";
import ListLink from "~/components/list-link";
import Panel from "~/components/panel";
import { ScoreboardUserContext } from "~/contexts";
import type { ContentTopicsQuery } from "~/types/api";

export default function ContentPages() {
  const {
    contentData: { contentTopics }
  } = useLoaderData<{
    contentData: ContentTopicsQuery;
  }>();
  const user = useContext(ScoreboardUserContext);
  invariant(user, "ScoreboardUserContext is required");

  const grouped = _.groupBy(
    contentTopics.filter((ct) => ct.status !== "Archived"),
    "status"
  );

  return (
    <Panel>
      <Panel.Header
        title="My Content Pages"
        titleClassName="text-center"
        floatButton
        button={
          <ButtonLink
            to={`/content/planning?owner=${user.id}`}
            className="show-when-hover-parent"
            small
          >
            View List
          </ButtonLink>
        }
      />
      <ListGroup>
        {CONTENT_STATUSES_WITH_DESCRIPTIONS.map(({ value: status }) => (
          <ListLink
            to={`/content/planning?owner=${user.id}`}
            className="flex items-center space-x-2"
            key={status}
          >
            <span className="flex-1">{status}</span>
            {grouped[status] ? (
              <Badge pill label={grouped[status].length} />
            ) : null}
          </ListLink>
        ))}
      </ListGroup>
    </Panel>
  );
}
