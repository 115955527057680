import _ from "lodash";
import { useLoaderData } from "react-router";
import PieChart from "~/components/charts/pie";
import Panel from "~/components/panel";
import type { PortfolioContributionQuery } from "~/types/api";
import { dayIsSameOrBefore, endOfMonth } from "~/utils/dates";
import { formatNumber } from "~/utils/formatting";

export default function PortfolioContribution() {
  const {
    portfolioData: { employee, contribution }
  } = useLoaderData<{ portfolioData: PortfolioContributionQuery }>();

  const result = contribution ? contribution[0] : null;
  const goals = _.orderBy(employee?.billableGoals, "effectiveDate", "desc");
  const goal =
    Number.parseFloat(
      goals.find((g) =>
        dayIsSameOrBefore(g.effectiveDate, endOfMonth(new Date()))
      )?.portfolioGoal || "0"
    ) / 100;
  const actual = Number.parseFloat(result?.sum || "0");
  const goalHit = actual >= goal;

  const data = [
    {
      id: "Your Projects",
      value: actual,
      color: goalHit ? "#10B981" : "#F87171"
    },
    {
      id: "Other Projects",
      value: 1 - actual,
      color: "#FDE68A"
    }
  ];

  return (
    <Panel className="mb-0">
      <Panel.Header
        title="Portfolio Contribution"
        titleClassName="text-center"
      />
      <Panel.Body
        className={result ? "p-1" : undefined}
        style={{ height: 251 }}
      >
        {result ? (
          <PieChart
            data={data}
            customColors
            valueFormat={(d) => formatNumber(d, { format: "0%", zeroes: true })}
          />
        ) : (
          <div className="pt-4 text-center">
            <em>No hours entered for this month yet</em>
          </div>
        )}
      </Panel.Body>
      <Panel.Footer className="text-center">
        Goal:{" "}
        {goal > 0
          ? `${formatNumber(goal, {
              format: "0%"
            })} of All Hours`
          : "Not Set"}
      </Panel.Footer>
    </Panel>
  );
}
