import { useLoaderData } from "react-router";
import invariant from "tiny-invariant";
import ButtonLink from "~/components/button-link";
import { IconMetrics } from "~/components/icons";
import IndividualBoard from "~/components/kanban/individual-board";
import Panel from "~/components/panel";
import PUMTable from "~/components/projects/pum-table";
import BillableHours from "~/components/scoreboard/billable-hours";
import IndividualRocks from "~/components/scoreboard/individual-rocks";
import PortfolioContribution from "~/components/scoreboard/portfolio-contribution";
import ScoreboardProject from "~/components/scoreboard/project-counts";
import QuarterlyMetrics from "~/components/scoreboard/quarterly-metrics";
import RecentProjects from "~/components/scoreboard/recently-billed";
import TaskCounts from "~/components/scoreboard/task-counts";
import type { loader } from "~/routes/_index/route";
import { useCurrentUser } from "~/utils/auth";

export default function DashboardStandard() {
  const currentUser = useCurrentUser();
  const loaderData = useLoaderData<typeof loader>();
  const { quarter, dashboard } = loaderData;
  invariant(dashboard === "Standard", "Dashboard data doesn't match");
  const { user, pumData, employee } = loaderData;

  return (
    <div>
      <div
        className="mb-8 grid w-full gap-8"
        style={{
          gridTemplateColumns: "repeat(auto-fit, minmax(340px, 1fr))"
        }}
      >
        <div>
          <TaskCounts />
          <ScoreboardProject />
        </div>
        <div>
          <BillableHours quarter={quarter} employee={employee} />
        </div>
        <div>
          <QuarterlyMetrics quarter={quarter} />
        </div>
        <div>
          <PortfolioContribution />
        </div>
      </div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <IndividualBoard />
        </div>
        <div>
          <IndividualRocks />
        </div>
      </div>
      <RecentProjects />
      <Panel>
        <Panel.Header
          title="Projects that Need Attention"
          titleClassName="text-center"
          floatButton
          button={
            <div className="pr-4">
              <ButtonLink
                small
                to={"/projects/reports/projects-under-management"}
              >
                <IconMetrics /> View Full Report
              </ButtonLink>
            </div>
          }
        />
        <PUMTable
          userId={user?.id || currentUser.id}
          onlyWithIssues
          user={pumData!.user}
          projects={pumData!.projects}
        />
      </Panel>
    </div>
  );
}
