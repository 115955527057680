import { useLoaderData } from "react-router";
import invariant from "tiny-invariant";
import IndividualBoard from "~/components/kanban/individual-board";
import ProspectMeetings from "~/components/sales/gauge-prospect-meetings";
import IndividualRocks from "~/components/scoreboard/individual-rocks";
import type { loader } from "~/routes/_index/route";
import { useCurrentUser } from "~/utils/auth";

export default function DashboardIT() {
  const loaderData = useLoaderData<typeof loader>();
  const currentUser = useCurrentUser();
  const { dashboard, quarter } = loaderData;
  invariant(dashboard === "President", "Dashboard data doesn't match");
  return (
    <div>
      <div className="grid grid-cols-4 gap-4">
        <div className="col-span-3">
          <IndividualBoard />
        </div>
        <div>
          <IndividualRocks />
          <ProspectMeetings
            quarter={quarter}
            name="Prospect Meetings: President"
            userId={currentUser.id}
          />
        </div>
      </div>
    </div>
  );
}
